import { z } from 'zod';

const envSchema = z.discriminatedUnion('NEXT_PUBLIC_SENTRY_ENABLED', [
  z.object({
    NEXT_PUBLIC_SENTRY_ENABLED: z.literal(true),
    NEXT_PUBLIC_SENTRY_DSN: z.string().min(1),
    NEXT_PUBLIC_SST_STAGE: z
      .union([z.literal('production'), z.literal('staging'), z.literal('dev')])
      .default('dev'),
    NEXT_PUBLIC_VERSION: z.string().min(1),
  }),
  z.object({
    NEXT_PUBLIC_SENTRY_ENABLED: z.literal(false),
  }),
]);

const parsedEnv = envSchema.safeParse({
  NEXT_PUBLIC_SENTRY_ENABLED: process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true',
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_SST_STAGE: process.env.NEXT_PUBLIC_SST_STAGE,
  NEXT_PUBLIC_VERSION: process.env.NEXT_PUBLIC_VERSION,
});

if (!parsedEnv.success) {
  throw new Error(
    `Invalid internal environment variables: ${JSON.stringify(parsedEnv.error.format(), null, 2)}`
  );
}

export const env = parsedEnv.data;
